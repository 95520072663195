import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
	state: {
		accessToken: null,
		refreshToken: null,
		tokenExpiryTime: null,
		user: null,
		permissions: null,
		residentialPermissions: null,
		managingCustomers: [],
		userReady: false
	},
	getters: {
		authenticated(state) { // Used to determine if the user has a valid access token.
			return (state.accessToken !== null);
		},
		timeUntilExpiry(state) { // Returns the number of microseconds until the access token expires (based on the token expiry time, which is an exact timestamp).
			return state.tokenExpiryTime - Date.now();
		},
		isInternalUser(state) { // Used to check if the user is an internal user.
			return (state.user.access_type == 'internal');
		},
		hasPermission(state, getters) { // Used to check if the user has the given permission for the given module.
			return (module, permission, allowAllCustomerPermissions) => {
				const availablePermissions = (allowAllCustomerPermissions === true && getters.managingCustomer !== null && getters.managingCustomer.customer_type == 'residential') ? state.residentialPermissions : state.permissions; // If the user is managing a residential customer, and we're allowing all permissions for residential customers, use their residential customer permissions, otherwise use their standard permissions.
				return (availablePermissions.hasOwnProperty(module) && availablePermissions[module].includes(permission));
			};
		},
		managingCustomer(state) { // Returns the customer that the user is currently managing, or NULL if they aren't currently managing a customer.
			return (state.managingCustomers.length > 0) ? state.managingCustomers[state.managingCustomers.length - 1] : null;
		},
		managingCustomerName(state, getters) { // Returns the customer name of the customer that the user is currently managing, or NULL if they aren't currently managing a customer.
			return (getters.managingCustomer !== null) ? getters.managingCustomer.customer_name : null;
		},
		isManagingCustomer(state, getters) { // Used to determine whether the user is currently managing a customer.
			return (getters.managingCustomer !== null);
		}
	},
	mutations: {
		readFromSession(state) { // Reads the access token, refresh token, and token expiry time from the session storage.
			state.accessToken = sessionStorage.getItem('access-token');
			state.refreshToken = sessionStorage.getItem('refresh-token');
			state.tokenExpiryTime = sessionStorage.getItem('token-expiry-time');
			
			setAccessToken(state.accessToken); // Sets the access token that's actually used for API calls.
		},
		setAccessToken(state, value) { // Sets the value of the access token.
			state.accessToken = value;
			setSessionValue('access-token', value);
			if(value !== null) { // Also set the access token that's actually used for API calls.
				setAccessToken(value);
			}
		},
		setRefreshToken(state, value) { // Sets the value of the refresh token.
			state.refreshToken = value;
			setSessionValue('refresh-token', value);
		},
		setTokenExpiryTime(state, value) { // Sets the value of the token expiry time.
			state.tokenExpiryTime = value;
			setSessionValue('token-expiry-time', value);
		},
		setUser(state, value) { // Sets the data for the authenticated user.
			// Set the user's permissions in the state. An empty permissions list is returned from the API as an empty array, so we need to convert this to an empty object instead.
			state.permissions = !Array.isArray(value.permissions) ? value.permissions : {};
			state.residentialPermissions = !Array.isArray(value.residential_permissions) ? value.residential_permissions : {};
			
			// Remove the user's permissions from the user details, and set the user details in the state.
			delete value.permissions;
			delete value.residential_permissions;
			state.user = value;
		},
		setUserReady(state, value = true) { // Sets the userReady state to indicate that the authentication process has completed.
			state.userReady = value;
		},
		readManagingCustomersCookie(state) { // Reads the hierarchy of customers that the user is managing from the cookie.
			state.managingCustomers = readManagingCustomersCookie();
		},
		setManagingCustomers(state, customers) { // Sets the customers that the user is currently managing.
			state.managingCustomers = customers;
			setManagingCustomersCookie(); // Also set the cookie so that future tabs automatically manage the same customer.
		},
		stopManagingCustomer(state) { // Removes the customer currently being managed, returning to the previous customer.
			state.managingCustomers.pop();
			setManagingCustomersCookie(); // Also set the cookie so that future tabs don't manage this customer.
		},
		clearManagingCustomers(state) { // Removes the hierarchy of customers that the user is managing.
			state.managingCustomers = [];
			setManagingCustomersCookie(); // Also remove the cookie so that future tabs don't manage any customer.
		}
	},
	actions: {
		clearAccessToken({commit}) { // Clear the auth details from the application state to effectively log the user out.
			commit('setAccessToken', null);
			commit('setRefreshToken', null);
			commit('setTokenExpiryTime', null);
		},
		async getAccessToken({commit}, authDetails = null) { // Performs the API request to obtain an access token, processes the response, and sets the new token in the application state.
			const data = await getAccessToken(authDetails);
			if(data && data.access_token && data.refresh_token && data.expires_in) { // Only continue if the API request was successful, and the response contains all of the expected fields.
				commit('setAccessToken', data.access_token);
				commit('setRefreshToken', data.refresh_token);
				
				const tokenExpiryTime = Date.now() + ((data.expires_in - 600) * 1000); // The expires_in field contains the number of seconds until access token expires. We convert it to milliseconds for use in the JavaScript Date object, and subtract 600 seconds so that we refresh the token 10 minutes early.
				commit('setTokenExpiryTime', tokenExpiryTime);
				
				return true;
			}
			
			return false;
		},
		async updateUserDetails({commit, dispatch}) { // Performs the API request to obtain the user details for the logged in user.
			try {
				const response = await this.HTTP.get('user');
				commit('setUser', response.data.data);
			} catch(error) { // If there was an error obtaining the user details, clear the access token from the session storage and redirect to the login page again.
				dispatch('clearAccessToken');
				this.$root.sendToLogin();
			}
		},
		async setManagingCustomer({dispatch, getters}, {customerId, skipRedirect}) { // Sets the customer that the user is currently managing.
			// Before setting the customer that the user is managing, ensure that the user has the appropriate permissions to manage customers. This is necessary for internal users, and for customer users that are already managing a corporate customer (meaning that the new customer they are attempting to manage is a child customer).
			if(getters.isInternalUser || getters.managingCustomer !== null) {
				await dispatch('updateUserDetails');
				if(!getters.hasPermission('customers', 'manage', true)) {
					this.$root.loginError = true;
					return;
				}
			}
			
			// Get the updated customer details for the given customer, to ensure that the user actually has access to this customer.
			try {
				const response = await this.HTTP.get('customers/' + customerId);
				dispatch('setManagingCustomers', response.data.data);
				if(skipRedirect !== true) { // After switching to the new customer, return to the home page, unless this is specifically supressed.
					this.$root.$router.push('/');
				}
			} catch(error) { // If there was an error obtaining the customer details, display the critical error message.
				this.$root.loginError = true;
			}
		},
		setManagingCustomers({commit, state, getters}, customer) { // Adds the given customer to the hierarchy the customers that the user is currently managing.
			// Set just the fields that are required, since this is stored in the cookie so we need to limit the size.
			customer = {id: customer.id, customer_type: customer.customer_type, customer_name: getCustomerName(customer), status: customer.effective_status};
			
			// Add the given customer to the hierarchy the customers that the user is currently managing.
			let customers = state.managingCustomers;
			if(!getters.isInternalUser && customer.customer_type == 'residential') { // Selecting a residential customer (as a customer user) completely replaces previously selected customer.
				customers = [customer];
			} else { // Selecting a corporate customer (or any customer as an internal user) adds it to the hierarchy of customers that the user is managing.
				customers.push(customer);
			}
			
			// Update the state with the new customer hierarchy.
			commit('setManagingCustomers', customers);
		}
	}
});

// Sets the value for the given field in the session storage. If the value provided is NULL, removes the value from the session storage instead of updating it.
function setSessionValue(field, value) {
	if(value === null) {
		sessionStorage.removeItem(field);
	} else {
		sessionStorage.setItem(field, value);
	}
}

// Sets the access token in the HTTP object that's used for API calls.
function setAccessToken(accessToken) {
	store.HTTP.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`; // Sets the access token that's actually used for API calls.
}

// Performs the API request to obtain an access token and returns the response.
async function getAccessToken(authDetails = null) {
	try {
		// Set the correct request details depending on whether we're using an auth code or a refresh token to obtain the access token.
		const requestData = { client_id: process.env.VUE_APP_AUTH_CLIENT_ID }
		if(authDetails === null) {
			requestData.grant_type = 'refresh_token';
			requestData.refresh_token = store.state.refreshToken;
		} else {
			requestData.grant_type = 'authorization_code';
			requestData.redirect_uri = `${window.location.origin}/auth`;
			requestData.code_verifier = authDetails.codeVerifier;
			requestData.code = authDetails.authCode;
		}
		
		// Perform the API request to obtain an access token and returns the response.
		const response = await axios.post(`${process.env.VUE_APP_AUTH_URL}token`, requestData);
		return response.data;
	} catch(error) { // If an error was encountered, return FALSE to display an error message.
		return false;
	}
}

// Returns the customer name for the given customer, depending on the customer type.
function getCustomerName(customer) {
	switch(customer.customer_type) {
		case 'corporate':
			return customer.company_details.company_name;
		case 'residential':
			return `${customer.customer_details.first_name} ${customer.customer_details.last_name}`.trim();
	}
}

// Reads the hierarchy of customers that the user is managing from the cookie.
function readManagingCustomersCookie() {
	// Check if there are any customers in the session storage. If so, that's the customer list for this particular tab, so we ignore the main list from the cookie.
	const sessionCustomers = sessionStorage.getItem('managing-customers');
	if(sessionCustomers !== null) {
		return JSON.parse(sessionCustomers);
	}
	
	// If there was nothing in the session storage, check if there are any customers in the cookie instead.
	const cookies = document.cookie.split(';')
	for(let cookie of cookies) { // Loop through each cookie to find the 'managing-customers' cookie.
		cookie = cookie.split('=');
		const cookieName = cookie.shift();
		const cookieValue = cookie.shift();
		
		// If this is the 'managing-customers' cookie, parse the customer list and return it.
		if(cookieName == 'managing-customers') {
			sessionStorage.setItem('managing-customers', cookieValue); // Also set the data in the session storage that is used for this particular tab.
			return JSON.parse(cookieValue);
		}
	}
	
	// If the 'managing-customers' cookie wasn't found, simply return an empty array.
	return [];
}

// Sets the cookie containing the hierarchy of customers that the user is managing.
function setManagingCustomersCookie() {
	let customers = store.state.managingCustomers;
	if(customers.length > 0) { // If the user is managing a customer, set the cookie to the a JSON-encoded customer string of the customer hierarchy.
		customers = JSON.stringify(customers);
		document.cookie = `managing-customers=${customers}; path=/`;
		sessionStorage.setItem('managing-customers', customers); // Also set the data in the session storage that is used for this particular tab.
	} else { // If the user isn't managing a customer, delete the existing cookie.
		document.cookie = 'managing-customers=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
		sessionStorage.removeItem('managing-customers'); // Also remove the data from the session storage that is used for this particular tab.
	}
}

export default store;