import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store';
import VueGoogleMaps from '@fawmi/vue-google-maps'

import { HTTP } from '@/assets/js/http';
import '@/assets/scss/main.scss';

const app = createApp(App);
app.config.globalProperties.HTTP = HTTP;
store.HTTP = HTTP;

app.use(store);
app.use(router);
app.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
		libraries: 'places'
	}
})

const rootView = app.mount('#app');
store.$root = rootView;
