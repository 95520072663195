<template>
	<button type="button" class="disclosure-button" :disabled="disabled" :class="{'enabled': this.enabled}" @click="toggle">
		<span class="enabled">&#9650;</span>
		<span class="disabled">&#9660;</span>
	</button>
</template>

<script>
	export default {
		props: {
			disabled: {
				type: Boolean,
				default: false
			},
			toggleEnabled: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				enabled: false
			}
		},
		created() {
			this.enabled = this.toggleEnabled;
		},
		methods: {
			toggle() { // Called when the button is clicked, and used to toggle the button status.
				this.enabled = !this.enabled;
				this.$emit('status-changed', this.enabled); // Sends an event to the parent component to signify that the button status has changed
			}
		},
		watch: {
			toggleEnabled: function(value) { // When the enabled property changes, update the "real" enabled status if it is different from the new value.
				if(value != this.enabled) {
					this.enabled = value;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.disclosure-button {
		background-color:transparent;
		background-repeat:no-repeat;
		border:none;
		outline:none;
		font-size:3rem;
		
		&.enabled .disabled,
		&:not(.enabled) .enabled{
			display:none;
		}
		
		&:enabled {
			cursor:pointer;
		}
	}
</style>