<template>
	<div v-if="loginError" class="error">An error has occurred.</div>
	<div v-else-if="userReady">
		<header>
			<div id="logo">
				<router-link to="/">
					<img src="@/assets/images/pennytel-logo.svg" />&nbsp;Portal
				</router-link>
				<div v-if="selectingCustomer" id="loading-customer" class="loading-indicator">
					<img src="@/assets/images/loading.gif" class="loading-customer">
				</div>
				<div v-else-if="showManagingCustomer" id="managed-customer" @click="showCustomerList = !showCustomerList">
					Viewing <span :id="!managingPrimaryCustomer ? 'customer-name' : ''">{{managingPrimaryCustomer ? 'My Account' : managingCustomerName}}</span>
					<disclosure-button :disabled="true" :toggle-enabled="showCustomerList"></disclosure-button>
				</div>
				<div v-if="showManagingCustomer" v-show="showCustomerList" id="select-customer-list">
					<div v-if="showResidentialCustomerList" v-for="(customerName, customerId) in user.residential_customers" v-show="customerId != managingCustomer.id" :class="{'user-primary-customer': (customerId == user.primary_customer && userResidentialCustomers > 2)}" @click="manageCustomer(customerId)">{{customerName}}</div>
					<router-link to="/customers" v-if="showResidentialCustomerList && user.corporate_customers > 0" @click="showCustomerList = false">Corporate Customers</router-link>
					<div v-if="!showResidentialCustomerList" @click="exitCustomer">Exit Customer</div>
				</div>
			</div>
			<nav>
				<router-link to="/" v-if="!isCustomerSelectionMode">Home</router-link>
				<router-link to="/service-qualification" v-if="!isManagingCustomer && hasPermission('service-qualification', 'perform')">Service Qualification</router-link>
				<router-link to="/customers" v-if="showCustomersLink">{{isCustomerSelectionMode ? 'Customer Selection' : 'Manage Customers'}}</router-link>
				<div v-if="showUsersLink && hasPermission('users', 'view', true)">
					<router-link to="/users">Manage Users</router-link>
					<div v-if="!isManagingCustomer && hasPermission('user-profiles', 'view')">
						<router-link to="/user-profiles">User Profiles</router-link>
					</div>
				</div>
				<router-link to="/agents" v-if="!isManagingCustomer && hasPermission('agents', 'view') && showAgentsLink">Manage Agents</router-link>
				<div v-if="isInternalUser && !isManagingCustomer && hasPermission('plans', 'view')">
					<router-link to="/plans">Manage Plans</router-link>
					<div>
						<router-link to="/plan-groups">Plan Groups</router-link>
					</div>
				</div>
				<router-link to="/services" v-if="isManagingCustomer && hasPermission('services', 'view', true)">Manage Services</router-link>
				<router-link to="/pending-services" v-if="!isManagingCustomer && hasPermission('services', 'view-pending')">Pending Services</router-link>
				<div id="user-profile">
					<img src="@/assets/images/user-profile.png" />
					<div id="user-options">
						<div>
							<div id="user-name">{{`${user.first_name} ${user.last_name}`.trim()}}</div>
							<router-link to="/change-password">Change Password</router-link>
							<router-link to="/api-tokens" v-if="hasPermission('api-tokens', 'api-access')">API Tokens</router-link>
							<router-link to="/logout">Log Out</router-link>
						</div>
					</div>
				</div>
			</nav>
		</header>
		<router-view/>
	</div>
</template>

<script>
	import sha256 from 'crypto-js/sha256';
	import Base64 from 'crypto-js/enc-base64';
	import DisclosureButton from '@/components/DisclosureButton';
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	
	export default {
		data() {
			return {
				freshLogin: false,
				loginError: false,
				showCustomerList: false,
				selectingCustomer: false
			}
		},
		computed: {
			showCustomersLink() { // Used to determine whether to show the navigation link for the Manage Customers page, since sometimes this is displayed in the user's customer list instead. Ultimately this is always displayed for internal users that have access to at least one customer type, or customer users that aren't managing a customer, or are managing a corporate customer and have the permission to view child customers.
				return ((this.isInternalUser && this.user.customer_access !== null) || (!this.isInternalUser && (!this.isManagingCustomer || (this.isManagingCustomer && this.managingCustomer.customer_type == 'corporate' && this.hasPermission('customers', 'view')))));
			},
			showUsersLink() { // Used to determine whether to show the navigation link for the Manage Users page. This isn't displayed for customer users managing a residential customer that isn't their primary customer or one of its descendants.
				return (!this.isCustomerSelectionMode && (this.isInternalUser || !this.managingResidentialCustomer || this.managingPrimaryCustomer || (this.user.primary_customer_descendants !== null && this.user.primary_customer_descendants.includes(this.managingCustomer.id))));
			},
			showAgentsLink() { // Used to determine whether to show the navigation link for the Manage Agents page. This is only available if the authenticated user has access to manage internal users, or has access to corporate customers, in additional to the permission to view agents.
				return (this.canManageInternalUsers || this.canManageCorporateCustomers);
			},
			isCustomerSelectionMode() { // Used to determine whether the user is in customer selection mode, meaning only the customers page and a few user-related pages are available.
				return (!this.isInternalUser && !this.isManagingCustomer);
			},
			userResidentialCustomers() {
				return Object.keys(this.user.residential_customers).length;
			},
			userTotalCustomers() { // Returns the total number of customers that the authenticated user has access to.
				return this.userResidentialCustomers + this.user.corporate_customers;
			},
			managingChildCustomer() { // Used to determine whether the user is managing a child customer of one of their customers. This is the case if there are multiple customers in the hierarchy or customers that they are managing.
				return (this.managingCustomers.length > 1);
			},
			managingPrimaryCustomer() { // Used to determine whether the user is currently managing their primary residential customer.
				return (this.isManagingCustomer && this.managingCustomer.id == this.user.primary_customer);
			},
			managingResidentialCustomer() { // Used to determine whether the user is currently managing a residential customer.
				return (this.isManagingCustomer && this.managingCustomer.customer_type == 'residential');
			},
			showManagingCustomer() { // Used to determine whether to display the customer that the user is managing below the Pennytel logo. This is always displayed for internal users (when managing a customer), or for customer users that have access to more than one customer, or are managing a child customer.
				return (this.isManagingCustomer && (this.isInternalUser || this.userTotalCustomers > 1 || this.managingChildCustomer));
			},
			showResidentialCustomerList() { // Used to determine whether to display the list of residential customers that the user has access to. This is displayed when the user is managing a residential customer (and isn't an internal user).
				return (!this.isInternalUser && this.managingResidentialCustomer);
			},
			canManageInternalUsers() { // Used to determine whether the authenticated user has the appropriate permission to manage internal users.
				return this.hasPermission('users', 'manage-internal')
			},
			canManageCorporateCustomers() { // Used to determine whether the authenticated user has the appropriate permission to manage corporate customers.
				return (this.user.customer_access == 'all' || this.user.customer_access == 'corporate');
			},
			...mapState([
				'user', 'userReady', 'managingCustomers'
			]),
			...mapGetters([
				'authenticated', 'timeUntilExpiry', 'isInternalUser', 'hasPermission', 'managingCustomer', 'managingCustomerName', 'isManagingCustomer'
			])
		},
		components: {
			DisclosureButton
		},
		async created() {
			// When the app is loaded, perform the necessary authentication.
			this.loginError = !await this.checkAuthenticiation();
			if(this.authenticated && !this.freshLogin) { // If the authentication was successful (and this isn't a fresh login, meaning the browser is about to be redirected), schedule the next refresh, then check if there is a cookie indicating which customer the user managing, get the user details of the logged in user, and automatically select the customer to manage if the user has a primary residential customer, or only has access to one customer.
				if(!this.checkUnnecessaryAuthRedirect()) { // Checks if the request includes the query string parameters for the redirect from the OAuth server, even though the user is already logged in, and if so, redirects back to the root domain without the query string parameters.
					await this.setRefreshTimer();
					this.readManagingCustomersCookie();
					await this.updateUserDetails();
					await this.checkAutoManageCustomer();
					this.detectLogout(); // Adds an event listener to detect a logout that is performed in any other open tabs, to automatically log out this tab as well.
				}
			}
		},
		methods: {
			async checkAuthenticiation() { // Checks if there is a valid access token in the session storage, and if not, performs the necessary authentication.
				this.checkSessionDetails(); // Gets the access token, refresh token, and token expiry time from the session storage.
				if(!this.authenticated) {
					this.freshLogin = true; // This prevents the page content from being displayed until after the redirect when first logging in.
					return await this.performAuthentication();
				}
				
				return true;
			},
			async performAuthentication() { // Performs the necessary authentication step depending on whether this request is for the redirect from the OAuth server.
				// Check if this request is for the redirect from the OAuth server, and if so, process the auth code.
				const authDetails = this.checkAuthRedirect();
				if(authDetails !== null) {
					return await this.executeLogin(authDetails);
				}
				
				// If this request isn't for the redirect from the OAuth server, send the user to the login page.
				this.sendToLogin();
				return true; // Returns TRUE to indicate that while we're not logged in, there isn't a login error, so the error message doesn't need to be displayed.
			},
			checkAuthRedirect() { // Checks if this request is for the redirect from the OAuth server, and if so, returns auth code, code verifier, and the URL that the user was originally attempting to access.
				// Get the code verifier, state, and the URL that the user was originally attempting to access from the session storage.
				const codeVerifier = sessionStorage.getItem('code-verifier');
				const state = sessionStorage.getItem('state');
				const intendedUrl = sessionStorage.getItem('intended_url');
				
				// If the required details were found in the session storage, process the authorisation.
				if(codeVerifier !== null && state !== null && intendedUrl !== null) {
					// Remove the details from the session storage to prevent repeating this step on future page loads.
					sessionStorage.removeItem('code-verifier');
					sessionStorage.removeItem('state');
					sessionStorage.removeItem('intended_url');
					
					// Get the auth code and state from the query string.
					const queryString = new URLSearchParams(window.location.search);
					const authCode = queryString.get('code');
					const responseState = queryString.get('state');
					
					// If there was an auth code and state parameter found in the query string, and the state matches that from the session storage, return the auth details to continue with the authorisation.
					if(authCode !== null && responseState !== null && state == responseState) {
						return {authCode, codeVerifier, intendedUrl};
					}
				}
				
				return null;
			},
			checkUnnecessaryAuthRedirect() { // Checks if the request includes the query string parameters for the redirect from the OAuth server, even though the user is already logged in, and if so, redirects back to the root domain without the query string parameters.
				// Get the auth code and state from the query string.
				const queryString = new URLSearchParams(window.location.search);
				const authCode = queryString.get('code');
				const state = queryString.get('state');
				
				// If there was an auth code and state parameter found in the query string, redirect back to the root domain without the query string parameters.
				if(authCode !== null && state !== null) {
					window.location.replace(window.location.origin);
					return true;
				}
				
				return false;
			},
			async executeLogin(authDetails) { // Performs the API request to obtain an access token using the auth details obtained from the checkAuthRedirect() method, and processes the result.
				if(await this.getAccessToken(authDetails)) { // If an access token was obtained successfully, redirect back to the URL that the user was originally attempting to access.
					window.location.replace(authDetails.intendedUrl);
					return true;
				}
				
				return false
			},
			async refreshAccessToken() { // Performs the API request to obtain a new access token using the stored refresh token, and processes the result.
				if(await this.getAccessToken()) { // If an access token was obtained successfully, schedule the next refresh.
					await this.setRefreshTimer();
				} else { // If there was an error obtaining a new access token, clear the existing one from the session storage and redirect to the login page again.
					this.clearAccessToken();
					this.sendToLogin();
				}
			},
			async setRefreshTimer() { // Schedules the next access token refresh based on the token expiry time.
				if(this.timeUntilExpiry - 600000 > 0) { // If the access token expires more than 10 minutes from now, set a timer to refresh it at the correct time.
					setTimeout(this.refreshAccessToken, this.timeUntilExpiry);
				} else { // If the token expires less than 10 minutes from now (or has already expired), refresh it immediately.
					await this.refreshAccessToken();
				}
			},
			sendToLogin() { // Sends the user to the login page.
				// Generate the code verifier, code challenge, and state.
				const verificationStrings = this.generateVerificationStrings();
				sessionStorage.setItem('intended_url', window.location.href);
				
				// Set the query string parameters for the OAuth authorisation page.
				const queryParameters = {
					client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
					redirect_uri: `${window.location.origin}/auth`,
					response_type: 'code',
					state: verificationStrings.state,
					code_challenge: verificationStrings.codeChallenge,
					code_challenge_method: 'S256'
				};
				
				// Generate the query string, and redirect the user to the login page.
				const queryString = this.generateQueryString(queryParameters);
				window.location.replace(`${process.env.VUE_APP_AUTH_URL}auth${queryString}`);
			},
			generateVerificationStrings() { // Generates a code verifier and associated code challenge, as well as a state string, for use in the OAuth flow.
				const codeVerifier = this.generateVerificationString('code-verifier');
				const state = this.generateVerificationString('state');
				const codeChallenge = this.generateCodeChallenge(codeVerifier);
				
				return {codeVerifier, state, codeChallenge};
			},
			generateVerificationString(verificationType) { // Generates a random string for use in the OAuth flow, and stores it in the session storage.
				const verificationStringLength = this.generateRandomInt(43, 128);
				const verificationString = this.generateRandomString(verificationStringLength);
				
				sessionStorage.setItem(verificationType, verificationString);
				return verificationString;
			},
			generateRandomInt(minValue, maxValue) { // Generates a random integer between minValue and maxValue.
				return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
			},
			generateRandomString(length) { // Generates a random string of the given length.
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
				
				let string = '';
				for(let i = 0; i < length; i++) {
					string += characters.charAt(Math.floor(Math.random() * characters.length));
				}
				
				return string;
			},
			generateCodeChallenge(code_verifier) { // Generates a code challenge for the given code verifier, for use in the OAuth flow.
				return sha256(code_verifier).toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
			},
			generateQueryString(parameters) { // Generates a query string using the given parameters.
				let queryString = '';
				for(const parameter in parameters) {
					const value = parameters[parameter];
					const prefix = (queryString == '') ? '?' : '&';
					queryString += `${prefix}${parameter}=${encodeURIComponent(value)}`;
				}
				
				return queryString;
			},
			async manageCustomer(customerId, skipRedirect = false) { // Sets the customer that the user is currently managing.
				// Once the user has selected a customer to manage, hide the customer list.
				this.showCustomerList = false;
				
				// Display the loading indicator, set the selected customer, and then hide the loading indicator again.
				this.selectingCustomer = true;
				await this.setManagingCustomer({customerId, skipRedirect});
				this.selectingCustomer = false;
			},
			async checkAutoManageCustomer() { // Checks if the authenticated user has a primary residential customer, or only has access to one customer, and automatically selects the given customer to manage.
				if(this.isCustomerSelectionMode) {
					if(this.user.primary_customer !== null) { // If the user has a primary residential customer, automatically manage it.
						await this.manageCustomer(this.user.primary_customer, true);
					} else if(this.userTotalCustomers == 1) { // If the user doesn't have a primary residential customer, but only has access to one customer, automatically manage it anyway.
						if(this.userResidentialCustomers == 1) {
							const customerId = Object.keys(this.user.residential_customers).shift();
							await this.manageCustomer(customerId, true);
						} else if(this.user.corporate_customers == 1) {
							await this.autoManageCorporateCustomer();
						}
					}
				}
				
				this.setUserReady(); // Once we reach this point, the authentication process is fully completed.
			},
			async autoManageCorporateCustomer() { // Called during initialisation when the authenticated user only has access to one corporate customer, so automatically manage the customer.
				try {
					// Get the details of the corporate customer that the user has access to.
					let response = await this.HTTP.get('customers/corporate');
					response = response.data.data;
					
					// Validate that the user really only has access to one customer, and select the given customer to manage.
					if(response.length == 1) {
						const customer = response.shift();
						await this.manageCustomer(customer.id, true);
					}
				} catch(error) { // If there was an error obtaining the customer details, display the critical error message.
					this.loginError = true;
				}
			},
			detectLogout() { // Adds an event listener to detect a logout that is performed in any other open tabs, to automatically log out this tab as well.
				window.addEventListener('storage', (event) => {
					if(event.key == 'LOGGING_OUT') {
						setTimeout(() => { // Perform the automatic logout after five seconds, to ensure that the server-side session has been invalidated.
							this.setUserReady(false); // Hides the user interface so that there isn't a flash to the customer selection mode when the hierarchy of customers that the user is cleared.
							this.clearAccessToken();
							this.clearManagingCustomers(); // Clear the cookie containing the hierarchy of customers that the user is managing.
							this.sendToLogin();
						}, 5000);
					}
				});
			},
			exitCustomer() { // Removes the customer currently being managed, returning to the previous customer.
				this.stopManagingCustomer();
				this.showCustomerList = false;
				
				// After exiting the customer, return to the home page (or for customer users that are no longer managing a customer, the customer list).
				const redirectLocation = this.isCustomerSelectionMode ? {name: 'customer-list'} : '/';
				this.$router.push(redirectLocation);
			},
			...mapActions([
				'getAccessToken', 'updateUserDetails', 'clearAccessToken', 'setManagingCustomer'
			]),
			...mapMutations([
				'setUserReady', 'readManagingCustomersCookie', 'stopManagingCustomer', 'clearManagingCustomers'
			]),
			...mapMutations({
				'checkSessionDetails': 'readFromSession',
			})
		}
	}
</script>

<style scoped lang="scss">
	header {
		a {
			color:var(--main-text-color);
		}
		
		#logo {
			float:left;
			font-size:1.5rem;
			font-weight:bold;
			position:relative;
			
			img:not(.loading-customer) {
				width:10rem;
				margin-top:5px;
				vertical-align:middle;
			}
			
			#managed-customer {
				font-size:1rem;
				font-weight:normal;
				text-align:right;
				cursor:pointer;
				
				#customer-name {
					font-style:italic;
				}
				
				.disclosure-button {
					font-size:1rem;
					cursor:pointer;
					color:var(--main-text-color);
				}
			}
			
			#select-customer-list {
				font-size:1.1rem;
				font-weight:normal;
				cursor:pointer;
				width:min-content;
				position:absolute;
				left:0;
				right:0;
				
				.user-primary-customer {
					font-weight:bold;
				}
			}
			
			#loading-customer {
				position:absolute;
				left:0;
				right:0;
			}
		}
		
		nav {
			float:right;
			font-size:1.1rem;
			font-weight:500;
			margin-bottom:20px;
			
			&>a, &>div:not(#user-profile) a {
				padding:0 10px;
				height:50px;
				line-height:50px;
				display:inline-block;
				border-bottom:4px solid #FFFFFF;
				
				&:hover {
					border-bottom-color:var(--main-accent-color);
				}
			}
			
			&>a, &>div:not(#user-profile)>a {
				border-right:1px solid var(--standard-border-color);
			}
			
			&>div:not(#user-profile) {
				display:inline-block;
				position:relative;
				
				&:not(:hover) div {
					display:none;
				}
				
				div {
					padding-top:15px;
					background-color:#FFFFFF;
					border:solid var(--standard-border-color);
					border-width:0 1px;
					margin:0 -1px;
					width:100%;
					position:absolute;
					top:100%;
					
					a {
						width:100%;
					}
				}
			}
		}
		
		#user-profile {
			display:inline-block;
			vertical-align:bottom;
			position:relative;
			overflow:visible;
			
			img {
				width:3rem;
				margin-left:10px;
				vertical-align:top;
			}
			
			&:hover #user-options {
				display:block;
			}
		}
		
		#user-options {
			display:none;
			position:absolute;
			top:100%;
			right:0;
		}
		
		#user-name {
			font-weight:bold;
			cursor:default;
		}
	}
	
	#user-options > div, #select-customer-list {
		margin-top:5px;
		background-color:var(--highlight-color-light);
		border:1px solid var(--standard-border-color);
		text-align:center;
		white-space:nowrap;
		
		div, a {
			display:block;
			padding:10px 20px;
			
			&:not(:last-child) {
				border-bottom:1px solid var(--standard-border-color);
			}
		}
	}
	
	#user-options a:hover, #select-customer-list a:hover, #select-customer-list div:hover {
		background-color:var(--highlight-color-dark);
	}
</style>