import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
const Home = () => import('@/views/Home.vue');
const Logout = () => import('@/views/Logout');
const ChangePassword = () => import('@/views/ChangePassword');
const ApiTokens = () => import('@/views/ApiTokens');
const ServiceQualification = () => import('@/views/ServiceQualification');
const CustomerList = () => import('@/views/CustomerList');
const UserList = () => import('@/views/UserList');
const UserProfileList = () => import('@/views/UserProfileList');
const AgentList = () => import('@/views/AgentList');
const PlanList = () => import('@/views/PlanList');
const PlanGroupList = () => import('@/views/PlanGroupList');
const ServiceList = () => import('@/views/ServiceList');
const NotFound = () => import('@/views/NotFound');

const routes = [
	{
		path: '/',
		component: Home,
		meta: {
			title: 'Home'
		}
	},
	{
		path: '/auth',
		component: Home,
		meta: {
			title: 'Log In',
			allowInternalView: true
		}
	},
	{
		path: '/logout',
		component: Logout,
		meta: {
			title: 'Log Out',
			allowInternalView: true
		}
	},
	{
		path: '/change-password',
		component: ChangePassword,
		meta: {
			title: 'Change Password',
			allowInternalView: true
		}
	},
	{
		path: '/api-tokens',
		component: ApiTokens,
		meta: {
			title: 'Manage API Tokens',
			authorisation: 'api-tokens.api-access',
			allowInternalView: true
		}
	},
	{
		path: '/service-qualification',
		component: ServiceQualification,
		meta: {
			title: 'Service Qualification',
			authorisation: 'service-qualification.perform',
			portalView: 'internal'
		}
	},
	{
		path: '/customers',
		name: 'customer-list',
		component: CustomerList,
		meta: {
			title: 'Manage Customers'
		},
		beforeEnter: async () => { // The Customers page has a unique authorisation check that requires specific conditions. For internal users, the customers page is available as long as they have access to at least one customer type. For customer users, it's available if the user isn't managing a customer, or is managing a residential customer and has access to corporate customers, or if they're managing a corporate customer and have the appropriate permission to view child customers.
			await checkUserReady(); // Wait until the authentication process has completed before checking the user authorisation.
			if(!((store.getters.isInternalUser && store.state.user.customer_access !== null) || (!store.getters.isInternalUser && (!store.getters.isManagingCustomer || (store.getters.managingCustomer.customer_type == 'residential' && store.state.user.corporate_customers > 0)) || (store.getters.isManagingCustomer &&  store.getters.managingCustomer.customer_type == 'corporate' && store.getters.hasPermission('customers', 'view'))))) {
				return {path: '/', replace: true};
			}
		}
	},
	{
		path: '/users',
		component: UserList,
		meta: {
			title: 'Manage Users',
			authorisation: 'users.view'
		},
		beforeEnter: async () => { // The Users page has a unique authorisation check that requires specific conditions. For customer users managing residential customers, they can only manage users for their own primary customer and its descendants.
			await checkUserReady(); // Wait until the authentication process has completed before checking the user authorisation.
			if(!store.getters.isInternalUser && store.getters.isManagingCustomer && store.getters.managingCustomer.customer_type == 'residential' && store.getters.managingCustomer.id != store.state.user.primary_customer && (store.state.user.primary_customer_descendants === null || !store.state.user.primary_customer_descendants.includes(store.getters.managingCustomer.id))) {
				return {path: '/', replace: true};
			}
		}
	},
	{
		path: '/user-profiles',
		component: UserProfileList,
		meta: {
			title: 'User Profiles',
			authorisation: 'user-profiles.view',
			portalView: 'internal'
		}
	},
	{
		path: '/agents',
		component: AgentList,
		meta: {
			title: 'Manage Agents',
			authorisation: 'agents.view',
			portalView: 'internal'
		},
		beforeEnter: async () => { // The Agents page has a unique authorisation check that requires additional permissions (either the ability to manage internal users, or access to corporate customers).
			await checkUserReady(); // Wait until the authentication process has completed before checking the user authorisation.
			if(!store.getters.hasPermission('users', 'manage-internal') && !(store.state.user.customer_access == 'all' || store.state.user.customer_access == 'corporate')) {
				return {path: '/', replace: true};
			}
		}
	},
	{
		path: '/plans',
		component: PlanList,
		meta: {
			title: 'Plans',
			authorisation: 'plans.view',
			portalView: 'internal'
		}
	},
	{
		path: '/plan-groups',
		component: PlanGroupList,
		meta: {
			title: 'Plan Groups',
			authorisation: 'plans.view',
			portalView: 'internal'
		}
	},
	{
		path: '/services',
		component: ServiceList,
		meta: {
			title: 'Services',
			authorisation: 'services.view',
			portalView: 'customer'
		}
	},
	{
		path: '/pending-services',
		component: ServiceList,
		props: {pendingServices: true},
		meta: {
			title: 'Pending Services',
			authorisation: 'services.view-pending',
			portalView: 'internal'
		}
	},
	{
		path: '/:catchAll(.*)',
		component: NotFound,
		meta: {
			title: 'Not Found',
			allowInternalView: true
		}
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

// Check the authenticated user's authorisation before navigating to a new route.
router.beforeEach(async to => {
	await checkUserReady(); // Wait until the authentication process has completed before checking the user authorisation.
	
	// If the user is not an internal user, and isn't currently managing a customer, the only valid page for them to view is the customer list (except for a few user-related pages).
	if(!store.getters.isInternalUser && store.getters.managingCustomer === null && to.name != 'customer-list' && to.meta.allowInternalView !== true) {
		return {name: 'customer-list', replace: true};
	}
	
	// Check if this route is only available for either the internal portal or the customer portal, and if the user is currently in the wrong mode, return to the home page.
	if(to.meta.hasOwnProperty('portalView')) {
		const portalView = to.meta.portalView;
		if((portalView == 'internal' && store.getters.managingCustomer !== null) || (portalView == 'customer' && store.getters.managingCustomer === null)) {
			return {path: '/', replace: true};
		}
	}
	
	// Check if this route requires a particular permission, and if the user doesn't have the given permission, return to the home page.
	if(to.meta.hasOwnProperty('authorisation')) {
		// Get the module and permission required to access the given route.
		const authorisation = to.meta.authorisation.split('.');
		const module = authorisation.shift();
		const permission = authorisation.shift();
		
		// Check if the authenticated user has access to the to the given module and permission, and if not, return to the home page.
		if(!store.getters.hasPermission(module, permission, true)) {
			return {path: '/', replace: true};
		}
	}
});

// Set the page title when navigating to a new route.
router.beforeEach(to => {
	document.title = to.meta.title + ' - Pennytel Portal'
});

// Asynchronous function used by the authorisation guard to wait until the authentication process has completed before attempting to validate the access to the route. This is required because on page load, the navigation guard may be called before the user details are loaded from the API.
const checkUserReady = () => {
	// Create a function to continually check the if the authentication process has completed, and resolve the promise once it has.
	const waitForUserReady = resolve => {
		if(store.state.userReady) {
			resolve();
		} else {
			setTimeout(waitForUserReady.bind(this, resolve), 1);
		}
	};
	
	// Return a promise that calls the function created above to wait for the authentication process to complete.
	return new Promise(waitForUserReady);
};

export default router;
